/**
 * Init event liste produits
 */
const loadEventListeProduits = () => {
    /**
     * Pagination
     */
    document.querySelectorAll('#listeProducts .pagination__page').forEach((elm) => {
        elm.addEventListener('click', (e) => {
            console.log(elm)
            const page = e.currentTarget.dataset.page;
            loadListeProduits(page);
        });
    })
};

/**
* Load liste produits
*/
const loadListeProduits = async (page = 1) => {
    
    const resp = await fetch(Routing.generate('liste_products', {
        page
    }));
    const html = await resp.text();
    document.getElementById('listeProducts').innerHTML = html;

    loadEventListeProduits();


    const urlFromPage = window.location.href; 
    const urlObject = new URL(urlFromPage);

    urlObject.searchParams.set('page', page);

    history.pushState({}, null, urlObject);

    NotifPanier.load();


};
loadEventListeProduits();
